
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,500;0,600;0,700;0,800;1,300&display=swap');


body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  font-family: 'Open Sans', sans-serif;
  color: #333;
  /* font-size: 1.6rem; */
  background-color: #FAFAFA;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: scroll;
  

}

  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
      /* background: transparent;  Optional: just make scrollbar invisible */
 }   

 input {border:0;outline:0;}
input:focus {outline:none!important;}

.container {
  /*
  width: 96%;
  max-width: 22rem;
  text-align: center;
  
  margin: 1.6rem auto;
  */
  padding-top:"5rem";
  overflow-x: hidden;
  
} 



.row {
  margin-bottom: "1rem";
}
.containersetup {
  /* width: 70%; */
  margin-top: 2.0rem auto;
  display: flex;
  justify-content: space-between;
}
.container-setup-textfields {
  /* width: 45%; */
  margin-left: 1.0rem;
  
  
}
.container-setup-demo {
  padding-top: 2%;
  /* width: 45%; */
  margin: 2.0rem auto;
  margin-top: 0px;
  
}
.container-setup-calendar {
  overflow-y: scroll;
  max-height: 850px;
}

.button-box-demo {
  text-align: center;
  background-color: white;
  margin: 0 0 1.5rem 0 ;
  margin-bottom: 10px;
  border-radius: 5px;
  font-weight: bold;
}
.grey-box-demo {
  background-color: rgb(242, 242, 242);
  padding: 0.35rem;
  border-radius: 7px;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  width: 400px;
}
.text-box-demo {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1.25rem;
}
.grey-box-demo-google {
  background-color: rgb(242, 242, 242);
  border-radius: 20px;
  width: 400px;
}
.text-box-demo-google {
	padding: 5px 15px;
	margin: 10px 0 0.5rem 0.5rem;
	/* box-shadow: 0 0 0 2px #3f51b5 */
}

.button-demo-google {
	display:flex;
	justify-content: center;
	border:1px solid #dbdbdb;
	width:150px;
	max-width:150px;
	margin:10px 0 0px 10px;
	padding: 0 10px;
	border-radius: 25px;
}

.button-text-input{
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	padding: 0;
}

.image-demo-google{
	display: flex;
	justify-content: right;
	margin: 20px 0;
}

.card-demo-google {
	border: 2px solid rgb(242, 242, 242);
    border-radius: 20px;
    width: 400px;
    margin: 10px 0 10px 0;
}
.card-bottom-demo-google{
	display: flex;
	flex-direction: column;
	padding: 15px;
}
.card-image-demo-google{
	border-top-right-radius: 17px;
    border-top-left-radius: 17px;
    width: 100%;
    object-fit: cover;
}

.time{
  min-height: 50px;
  width: auto;
  border: 10 px solid red;
  color: green;
  text-align: center; 
}

.fewo-counter{
  min-height: 50px;
  width: auto;
  text-align: center; 
}

.button-times {
  background-color: #4CAF50; /* Green #4CAF50; */
  border: 2px solid #4CAF50;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 16px;
  margin-left: 3px;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 4px;
  width: 98%;
  padding: 12px 10px;
  z-index: 0;
  float: left;
  -webkit-appearance: none;
  transition: box-shadow 0.5s;   
}
.button-times-disabled {
  background-color: grey; /* Green #4CAF50; */
  border: 2px solid grey;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 16px;
  margin-left: 3px;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 4px;
  width: 98%;
  padding: 12px 10px;
  z-index: 0;
  float: left;
  -webkit-appearance: none;
  transition: box-shadow 0.5s;
  cursor:not-allowed;
}

.button-times:hover {
  background-color: white;
  color: #4CAF50;
  cursor:pointer;
  border: 2px solid #4CAF50;
}

.button-confirm {
  
 
  background-color: #4CAF50; /* Green */
  border: 2px solid #4CAF50;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 16px;

  margin-left: 5px;
  margin-top: 10px;
  margin-right: 5px; /* there still seems to be a gap on the right side*/
  border-radius: 4px;
  width: 0%;

  padding: 12px 10px;
  /* display:none; */
  /* visibility: hidden; */
  float: right;
}

.button-confirm-hide {
	display: none;
}

.button-confirm-show {
	display: block;
}

.button-confirm:hover {
  
  right: 0px;
  z-index: -1;
  background-color: white;
  color: #4CAF50;
  cursor:pointer;
  border: 2px solid #4CAF50;
}

.shrink-button {
	animation: shrink ease-in 500ms forwards;
}
 @keyframes shrink {
	0% {
		width: 80%;
	}
	
	100% {
		width: 47%;
	}
}

.show-button {
	animation: show 500ms ease-in forwards;
	/* animation-delay: 2000ms; */
}

	@keyframes show {
		0% {
			width: 0%;
		}
		100% {
			width: 47%;
		}

}

.button-cancel {
  background-color: #C93B1C; /* Red */
    border: 2px solid #C93B1C;
    color: white;
    padding: 12px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    /* margin-left: 3px; */
    margin-top: 25px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 15px;
    font-weight: bold;
  
  }
.button-cancel-disabled {
	background-color: grey;
	border: 2px solid grey;
	color: white;
	padding: 12px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin-left: 3px;
	margin-top: 25px;
	border-radius: 4px;
	width: 98%;
	margin-bottom: 15px;
	font-weight: bold;
}

  .button-cancel:hover {
    background-color: white;
    cursor:pointer;
    color:#C93B1C;
    border: 2px solid #C93B1C;
  }


.headerbox {
  display: block;
  /*margin: 0 auto;*/
  margin-right: auto;
  margin-bottom: 2.0rem;
  margin-left: auto;
  border-radius: 8px;
  border: 1px solid #dadce0;
  height: auto;
  padding-top: 1.0rem;
  padding-bottom: 1.0rem;
  padding-right:1.0rem;
  width: 100%;
}

.title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  height:auto;
}

.headline {
  margin: 0 auto;    
  text-align: left;
  padding-left: 1.0rem;
  margin-bottom: 0.5rem
}

.duration {
  margin: 0 auto;
  text-align: left;
  padding-left: 1.0rem;
  margin-bottom: 0.5rem
}

.location {
  margin-top: 0 auto;
  text-align: left;
  padding-left: 1.0rem;
}

.calendar {
  /* border: 1px solid black; */
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 10px;
  margin: 0 0 1rem;
}

.displayedTime {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.timezone-dropdown {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #333;
  font-size: 14px;
  font-weight: bold;
  padding-left: 1.0rem;
  text-align: center;
  width: 41%;
}
.timezone-timeformat{
  display: flex;
  justify-content: space-evenly;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px soild rgba(0,0,0,0.1);
}

.containerbox {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid #f2f2f2;
  padding: 3rem;
  border-radius: 10px;
  text-align:"center";
  background-color: white;
  box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.containerbox-credentials {
  max-width: 750px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid #f2f2f2;
  padding: 30px;
  border-radius: 10px;
  text-align:"center";
  background-color: white;
  box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.containerbox-success {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  border: 1px solid #f2f2f2;
  padding: 30px;
  border-radius: 10px;
  text-align:"center";
  background-color: white;
  box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

.helper {
  text-align: center;
}

.help-icon{
  cursor: pointer;

}

.icons {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bin {
  margin-right:5px;
}

.signup-button {
  text-align: center;
}

@media (min-width: 601px) {
  .containerrr {

  
  display: block;
  
  /*margin: 0 auto;*/
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  margin-top: 5%;
  width: 35%;
  border-radius: 8px;
  border: 1px solid #dadce0;
  height: auto;
  
  /*
  margin-left: 35%;
  margin-right: 35%;
  */
  
  
 
    }
}

  .info {

  /* border-radius: 8px;
  border: 1px solid #dadce0; */
  display: block;
  /* height: auto; */
  height: auto;
  width: auto;
 margin: 0 auto;
  
 
  }

  .title  {

/*  border-radius: 8px;
  border: 1px solid #dadce0; */
  margin-bottom: 2rem;

  
  display: block;
  /* height: auto; */
  height: auto;
  width: auto; /*auto to make it responsive*/
  
  min-height: 50px;

  color: #202124;
  
  padding-top: 5%; 
  padding-left: 5%;
  padding-right: 5%;
 
  
  margin-top: 0 auto;
  margin-left: 0 auto;
  margin-right: 0 auto;
  
  
  
}

  .div-btn {

  display: block;
  /* height: auto; */
  height: auto;
  width: auto;
  
  min-height: 50px;

  color: #202124;
  
  /* padding-top: 3%; */
  padding-left: 3%;
  padding-right: 3%;
 
  margin-bottom: 0;
  /* margin-top: 15%; */
  /* margin-bottom: 5%; */
  }



  .h1 {
  text-align: center;
  font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
  font-size: 20px;
  font-weight: 400;
  white-space: normal;
  /* line-height: 1.3333;*/

  }

  .h2 {
  text-align: center;
  font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
  display: block;
  line-height: 2.3333;

  }

  .input{
  
  border: 2px solid #E9ECEF;
  display: block;
  text-align: center;
  font-size: 13px;
  border-radius: 4px;
  padding: 8px 5px;
  margin: 0 auto;
  width: auto;

  
  
  }

  .btn{
  display: block;
  text-align: center;
  background-color: #4CAF50; /* Green Blue:#4285f4*/
  border: 1px solid #4CAF50;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  /*padding: 8px 5px;*/
  
  margin: 0 auto;
  
  /*width: 25%;*/

  }

  .btn:hover {
  background-color: white; /* Green */
  border: 1px solid #4CAF50;
  color: #4CAF50;
  cursor:pointer;
  

  }
  .text{
    display: block;
     text-align: center;
    
  }
  #qm:hover{
    cursor:pointer;

  }
  
  a {
    text-decoration:none;
    text-align: center;
  font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
 }

 .mc {
  display: none;
  margin-top: 5%;
 

 }

.error {
 
display: block;
/* height: auto; */
height: auto;
width: auto; /*auto to make it responsive*/
padding: 8px 5px;
 
margin-top: 0 auto;
margin-left: 0 auto;
margin-right: 0 auto;
box-shadow: 0 0 0.5em red;
 
outline: 0 none;
transition: box-shadow 0.5s;
animation: shake 0.2s ease-in-out 0s 2;

border: 2px solid #E9ECEF;
  
  text-align: center;
  font-size: 13px;
  border-radius: 4px;
  
  margin: 0 auto;
 
    
}

@keyframes shake {
0%, 100% {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

10%, 30%, 50%, 70%, 90% {
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
}

20%, 40%, 60%, 80%, 100% {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
/* 0% { margin-left: 0rem; }
25% { margin-left: 0.5rem; }
75% { margin-left: -0.5rem; }
100% { margin-left: 0rem; } */
}
.success {

box-shadow: 0 0 0.5em green;
outline: 0 none;
transition: box-shadow 0.5s;   

border: 2px solid #E9ECEF;
display: block;
text-align: center;
font-size: 13px;
border-radius: 4px;
padding: 8px 5px;
margin: 0 auto;
width: auto;
 
}

.form-control{
  margin: 0;
}

/* #editEvent {
	position: absolute;
	right:0;
    width: 20%;
    transition: 1s;
} */

.container:hover #editEvent {
	transition: 1s;
    right: 0;
}
input { outline: none; }

.arrow-collapse{
	 display: flex;
     flex-direction: column-reverse;
    justify-content: center; 
}
.arrow-collapse:hover {
	cursor:pointer;
}

.button-save {
	background-color: #4CAF50;
    border: 2px solid #4CAF50;
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    height: 40px;
    z-index: 0;
    -webkit-appearance: none;
}
.button-save-disabled {
	background-color: grey; /* Green #4CAF50; */
	border: 2px solid grey;
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    height: 40px;
    z-index: 0;
    -webkit-appearance: none;
}
.button-reset {
	background-color: #C93B1C; /* Green #4CAF50; */
	border: 2px solid #C93B1C;
	font-weight: bold;
	color: white;
	text-align: center;
	font-size: 16px;
	margin-left: 3px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 4px;
	padding: 6px 10px;
	z-index: 0;
	-webkit-appearance: none;
}

.add-new-event-card:hover {
	/* background-color: #C93B1C; Green #4CAF50; */
	border-radius: 10px;
	cursor: pointer;
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.adding-new-event{
	border-radius: 10px;
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

/* .event-card:hover {
cursor: pointer;
} */

.slide-enter {
    right: -20%;
}

.slide-enter-active{
	right:0;
	transition: all 3000ms ease-out;
}

.slide-leave {
	right:0;
}

.slide-leave-active{
    right: -20%;
	transition: all 3000ms ease-out;
}

.center {
	display:flex;
	justify-content:center;
	position:"relative";
	top:"50%";
	transform:"translateY(-50%)"
}

.message-p  {
	max-width: 600px;
	margin-bottom: 12px;
	line-height: 24px;
	padding: 10px 20px;
	border-radius: 25px;
	position: relative;
	color: white;
	white-space:pre-wrap;
	word-break:break-word;
  }
  
  .message {
	display: flex;
  }
  
  
  .sent {
	flex-direction: row-reverse;
	display: flex;
    justify-content: right;

  }

	

  
  .sent p {
	color: white;
	background: #0b93f6;
	align-self: flex-end;
	text-align: left;
  }

  .received p {
	background: #e5e5ea;
	color: black;
	text-align: left;
  }

  @media (max-width: 600px){
	.received p {
		max-width: 90%;
	  }
	  .sent p {
		max-width: 90%;
	  }
}
  
  .chat-image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 2px 5px;
  }

  .chat-message-image{
	  max-height: 200px;
  }

  @media (max-width: 1000px){
	.pricing{
		display:flex;
		justify-content:center;
		padding:2rem;
	  }
}



  .pricingBox {
    display:flex;
	justify-content:center;
	/* width:30%; */
	width:375px;
	flex-direction:column;
	border:"";
	border-radius:10px;
	box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
	/* margin-right:"2rem" */
	max-width:400px;
	margin-right:2rem;
	text-align: left;
	padding:2rem;
    }

	.pricingBoxContent {
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0.25rem;
	text-align: left;
	}

.pricingBox:hover {
    -moz-transform: translate(0px, -2px);
    -ms-transform: translate(0px, -2px);
    -o-transform: translate(0px, -2px);
    -webkit-transform: translate(0px, -2px);
    transform: translate(0px, -2px);
	
}

.refresh-icon:hover {
	cursor: pointer;
	/* animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;  */
}

/* @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
} */

.remove {
	flex-direction: row;
	display: none;
}

.notification-bell:hover {
cursor: pointer;
}

.notification-item{
	display:"flex";
	justify-content:center;
	flex-direction: center;
	border-bottom: 0px;
	height: auto;
	padding: 3%;
	/* margin: ; */
	border-top: 1px solid grey;
/* 
	justifyContent:"center",
	flexDirection:"column",
	borderBottom:"",
	backgroundColor:"",
	borderRadius:"",
	minHeight:"",
	height:"auto",
	padding:"3%",
	margin:"0,25rem",
	borderBottom:"1px solid grey" */
}

.notification-item:hover {
cursor: pointer;
background-color: #f2f2f2;
}
.help-item:hover {
cursor: pointer;
background-color: #f2f2f2;
}

.button-delete-event {
	background-color: white; /* Red */
	  border: 2px solid white;
	  color: #C93B1C;
	  padding: 8px 18px;
	  text-align: center;
	  text-decoration: none;
	  display: inline-block;
	  font-size: 16px;
	  margin-left: 3px;
	  margin-top: 25px;
	  border-radius: 4px;
	  width: 98%;
	  margin-bottom: 15px;
	  font-weight: bold;
	
}

.button-delete-event:hover {
	background-color: #C93B1C;
	cursor:pointer;
	color:white;
	border: 2px solid #C93B1C;
}

.edit-event-sidebar-ui1-default {
	width:"34%"
}



.edit-event {
	width: "33%";
	visibility: visible;
	
}
.edit-event {
	width: "0%";
	visibility: hidden;
	
}

.lp-background{
	background-color: #F2F2F2;
	border-radius:20px;
	padding: 15px;
	margin:0 20%;
	display: flex;
	justify-content: center;
}
.lp-background:hover{
	background-color: #f2f2f28a;
	cursor: pointer;
}
.lp-background-fewo{
	background-color: #F2F2F2;
	border-radius:20px;
	padding: 15px;
	/* margin:0 20%; */
	display: flex;
	justify-content: center;
}
.lp-background-fewo:hover{
	background-color: #f2f2f28a;
	cursor: pointer;
}


.lp-titlebox-text{
	text-align: left;
}

@media (max-width: 601px) {
	.lp-titlebox-text{
		text-align: center;
		margin: 3rem 0 0 0;
	}
	.lp-cta-btn{
		display: flex;
		justify-content: center;
		padding: 2rem 0 0 0;

	}
	.lp-faq{
		width:80vw;
		margin: 0 auto;
	}
}

@media (min-width: 601px) {
	.lp-titlebox-text{
		text-align: left;
	}
	.lp-cta-btn{
		display: flex;
		justify-content: left;
		padding: 2rem 0 0 0;

	}
	.lp-faq{
		width:40vw;
		margin: 0 auto;

	}
}

@media (min-width: 987px) {
	.lp-testimonial-box{


		display: flex;
		justify-content: space-between;
	}
	.lp-testimonial{
		min-width: 20%;
		max-width: 40%;
		padding:3rem
	}
	
}
@media (max-width: 987px) {
	.lp-testimonial-box{
		display: flex;
		flex-direction: column;

	}
	.lp-testimonial{

		padding:3rem
	}
	
}

.lp-header {
	background-color: rgb(242, 242, 242);
}
@media (min-width: 601px) {
}


@media (max-width: 601px) {
	.hide-on-mobile {
		display: none;
   	}
	.lp-header {
		min-height: 4rem;
	}
	.p-2{
		padding: 2rem
	}
	.marginb-3{
		margin-bottom: 3rem;
	}
	
	
}

.flex-column-center {
	display: flex;
    flex-direction: column;
    justify-content: center;
}
.flex-column-end {
	display: flex;
    flex-direction: column;
    justify-content: end;
}
.flex-row-center {
	display: flex;
    flex-direction: row;
    justify-content: center;
}
.flex-row {
	display: flex;
    flex-direction: row;
    /* justify-content: center; */
}
.flex-center {
	display: flex;
    justify-content: center;
}

.btn-cta1{
	background-color: #4CAF50;
    border: 2px solid #4CAF50;
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 20px;
    margin-left: 3px;
    margin-right: 5px;
    border-radius: 4px;
    padding: 12px 22px;
    z-index: 0;
    -webkit-appearance: none;

}



.colhead {
	display:flex;
	flex-direction: row;
	justify-content:space-between;
	/* max-width:"80%"; */

}

@media (max-width: 601px) {
	.colhead {
	display:flex;
	flex-direction: column;
	justify-content:space-between;
	/* max-width:"80%"; */

}

}

.col2-left{
	display:flex;
	flex-direction:column;
	justify-content: center;
	max-width: 50%;

}

@media (max-width: 601px) {
	.col2-left{
		display:flex;
		flex-direction:column;
		justify-content: center;
		max-width: 100%;
	
	}

}

@media (max-width: 1000px) {
	.img3-col{
		flex-direction:column;
	
	}

	.pricing{
		display: flex;
		flex-direction: column;
		margin-right: 0rem;

	}

}
.pricingBox {
	width:100%;
	margin:1rem;
}
.pricing{
	display: flex;
}

/* @media (max-width: 1401px){
	.pricing{
		display: flex;
		flex-direction: column;
		margin-right: 0rem;
	}
	.pricingBox {
		width:100%;
		margin-right: 0rem;
		margin:1rem;
	}
} */

.img3-col{
	display:flex;
	justify-content: space-between;

}

.questionmark:hover {
	cursor: pointer;
}

.edit-event-sidebar {
	top:64px;
}

@media (max-height: 800px) {
	.edit-event-sidebar {
		top:10%;
	}

}

.example-enter {
	opacity: 0.01;
  }
  
  .example-enter.example-enter-active {
	opacity: 1;
	transition: opacity 5000ms ease-in;
  }
  
  .example-leave {
	opacity: 1;
  }
  
  .example-leave.example-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
  }

  .billing-details-box {
	border-radius: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 400px;
	margin-right: 2rem;
	/* margin-left: 1rem; */
  }
  @media (max-width: 600px) {
	.billing-details-box {
		box-shadow: none;
		padding: 0 0 20px 0;
		border-radius: 0px;
		border-bottom: 1px solid black;
		/* width: unset; */
		height: unset;

	}
}

  .google-accounts-box {
	border-radius: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: fit-content;
	min-height: 250px;
	margin: 2rem;
	/* transition: all 3000ms ease-out */
  }

  .billing-history-box {
	display: flex;
    flex-direction: column;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    width: 95%;
    border-radius: 10px;
	width:fit-content;
	margin:2rem 0 1rem 0;
	padding:0 2rem 2rem;
  }

  @media (max-width: 600px) {
	.billing-history-box {
		box-shadow: none;
		width: 65vw;
		padding:0;
	}
}
  .billing-pricing-box {
	width: 650px;
	height: 400px;
	border-radius: 10px;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
	padding: 2rem;
	display: flex;
	justify-content: space-between;
  }

  @media (max-width: 600px) {
	.billing-pricing-box {
		box-shadow: none;
		width: 65vw;
		padding:0;
		display: unset;
	}
}

  .billing-subscription-box {
	border-radius: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: fit-content;
	min-height: 250px;
	margin:2rem 0 0 0;
	width:fit-content;
  }

  @media (max-width: 600px) {
	.billing-subscription-box {
		box-shadow: none;
		width: 65vw;
		padding:2rem 0;
		margin: 2rem 0 0 0;
		min-height: 0;
		border-radius: 0px;
		border-bottom: 1px solid black;
		border-top: 1px solid black;
	}
}


  .billing-history-table-top {
    padding: 0 2rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
  }
  .hotelBookingData-categories-table-top {
    padding: 1rem 2rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /* width: 100%; */
  }

  .billing-history-list {
	display: flex;
    /* justify-content: space-between; */
    border-bottom: 1px solid black;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  .hotelBookingData-categories-list {
	display: flex;
    border-bottom: 1px solid #dbdbdb;
    padding:0.5rem 0;
  }

  
  .hotelBookingData-categories-list-calendars {
    /* justify-content: space-between; */
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
	width:100%
  }

  .button-status-active{
	background-color: #4CAF50; /* Green #4CAF50; */
	font-weight: bold;
	color: white;
	text-align: center;
	font-size: 16px;
	border-radius: 4px;
	width: auto;
	padding: 4px 8px;
	z-index: 0;
	float: left;
	-webkit-appearance: none;
	transition: box-shadow 0.5s;
  }
.button-status-inactive{
	background-color: #C93B1C; /* Green #4CAF50; */
	font-weight: bold;
	color: white;
	text-align: center;
	font-size: 16px;
	border-radius: 4px;
	width: auto;
	padding: 4px 8px;
	z-index: 0;
	float: left;
	-webkit-appearance: none;
	transition: box-shadow 0.5s;

}

.button-plans {
	background-color: #4CAF50; /* Green #4CAF50; */
	border: 2px solid #4CAF50;
	font-weight: bold;
	color: white;
	text-align: center;
	font-size: 16px;
	margin-left: 3px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 4px;
	width: 98%;
	padding: 12px 10px;
	z-index: 0;
	float: left;
	-webkit-appearance: none;
	transition: box-shadow 0.5s;   
  }
  
  .button-plans:hover {
	background-color: white;
	color: #4CAF50;
	cursor:pointer;
	border: 2px solid #4CAF50;
  }

  .button-plans-disabled {
	background-color: #4CAF50; /* Green #4CAF50; */
	border: 2px solid #4CAF50;
	font-weight: bold;
	color: white;
	text-align: center;
	font-size: 16px;
	margin-left: 3px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 4px;
	width: 98%;
	padding: 12px 10px;
	z-index: 0;
	float: left;
	-webkit-appearance: none;
	transition: box-shadow 0.5s;   
  }
  .button-plans-activate {
    background-color: #4CAF50;
    border: 2px solid #4CAF50;
    font-weight: bold;
    color: white;
    text-align: center;
    border-radius: 4px;
    width: 98%;
    z-index: 0;
    -webkit-appearance: none;
    transition: box-shadow 0.5s;
  }
  .button-plans-cancel {
    background-color: #C93B1C;
    border: 2px solid #C93B1C;
    font-weight: bold;
    color: white;
    text-align: center;
    border-radius: 4px;
    width: 98%;
    z-index: 0;
    -webkit-appearance: none;
    transition: box-shadow 0.5s;
  }

  .hover:hover{
	  cursor: pointer;
  }

  .buttons-install {
	background-color: #4caf50;
    border: 2px solid #4caf50;
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-size: 16px;
    /* margin-left: 3px; */
    /* margin-right: 5px; */
    /* margin-top: 10px; */
    border-radius: 4px;
    /* width: 98%; */
    /* padding: 12px 10px; */
    z-index: 0;
    float: left;
    -webkit-appearance: none;
    transition: box-shadow .5s;
  }

.facebook-signin {
	border: 1px solid #0084FE;
    background-color: #0084FE;
    border-radius: 10px;
    padding: 10px 50px;
    color: white;
    font-size: larger;
}

.keyword {
	border: 1px solid #F4F4F7;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 10px;
    width: fit-content;
    background: #F4F4F6;
    color: #3B3950;
	max-height: 2rem;
	margin-right: 0.75rem;
	margin-top: 0.5rem;
}

.box-shadow-transfrom{
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 1rem;
    border-radius: 10px;
}
.box-shadow-transfrom:hover{
	cursor: pointer;
    box-shadow: #3f51b5 0px 5px 15px;
    
}
.box-shadow-business{
	width: 225px;
	height: 200px;
	margin: 0.5rem;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	
}
.box-shadow-business:hover{
	cursor: pointer;
    width: 225px;
	height: 200px;
	margin: 0.5rem;
	border-radius: 10px;
	box-shadow: #3f51b5 0px 5px 15px;
}
.box-shadow-whatsapp-number{
	width: 100%;
	height: 50px;
}

.box-shadow-whatsapp-number:hover{
	width: 100%;
	height: 50px;
	cursor: pointer;
	box-shadow: #3f51b5 0px 5px 15px;
}

.box-shadow-business-selected{
	cursor: pointer;
    width: 225px;
	height: 200px;
	margin: 0.5rem;
	border-radius: 10px;
	box-shadow: #3f51b5 0px 5px 15px;
}

.demo-calendar-image{
	
	display:"grid";
	grid-area:" 1 / 1";
	justify-content:"center";
	
}
.demo-calendar-image:hover{
	display:"grid";
	grid-area:" 1 / 1";
	justify-content:"center";
	filter: opacity(30%);
	cursor: pointer;

}

.hotel-images{
	width: "10px";
}

.add-item{
	height: 2.5rem;
    border: 2px dotted grey;
    border-radius: 4px;
    margin-top: 1rem;
}
.add-item:hover{
	height: 2.5rem;
    border: 2px dotted grey;
    border-radius: 4px;
    margin-top: 1rem;
	cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.hotel-features-icons-enabled{
	cursor:pointer;
	margin: 0.25rem;

}
.hotel-features-icons-disabled{
	filter: opacity(30%);
	cursor: pointer;
	margin: 0.25rem;
}

.event-type-inactive{
	display:"flex";
	justify-content: space-evenly;
	font-weight: 100;
}
.event-type-inactive:hover{
	cursor: pointer;
}
.event-type-active{
	display:"flex";
	justify-content: space-evenly;
	border-bottom: 2px solid #3F51B5;
	font-weight: 600;
}

.button-select-hotelroom {
	background-color: #4CAF50; /* Green #4CAF50; */
	border: 2px solid #4CAF50;
	font-weight: bold;
	color: white;
	text-align: center;
	font-size: 16px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 4px;
	width: 100%;
	padding: 12px 10px;
	z-index: 0;
	float: left;
	-webkit-appearance: none;
	transition: box-shadow 0.5s;   
}
.button-select-hotelroom:hover {
	background-color: white;
	color: #4CAF50;
	cursor:pointer;
	border: 2px solid #4CAF50;
}

.MuiNativeSelect-icon {
	color: red
 }

.hc82 {
	padding: 0 10px 0 10px;
}
.buttons-slider {
	padding: 0 5px 0 5px;
}

.calendar-image {
	height: 120px;
	width: auto;
}
.beauty-slider {
	height: fit-content;
	width: auto;
}
.slick-dots {
	top: -40px;
	/* left: 100px;  */
	bottom: auto;
}
.slick-next:before {
    content: '→';
    color: black;
}
.slick-next:before {
    content: '→';
    color: black;
}
.slick-next{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: black;
    -webkit-font-smoothing: antialiased;
	right: 25px;
	top: 60px;
}

.slick-prev:before .slick-arrow .slick-prev{
	color: black;
	left: 20px;
	top: 10px;
	z-index: 100;
}

.collapse-facing-left {
	position: absolute;
    top: 50%;
    /* right: 25%; */
    z-index: 100;
    width: 7px;
    height: 48px;
    cursor: pointer;
    border-radius: 8px 0 0 8px;
    padding: 10px;
    background-color: rgb(242, 242, 242);
}
.collapse-right {
	position: absolute;
    top: 54%;
    /* right: 25%; */
    z-index: 100;
    width: 7px;
    height: 48px;
    cursor: pointer;
    border-radius: 8px 0 0 8px;
    padding: 10px;
    background-color: rgb(242, 242, 242);
}

.billing-headline {
	max-height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0.25rem 0.25rem 0.25rem 0;
    min-height: 1rem;
    margin-bottom: 0.75rem;
}

.react-router-button{
	color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.event-status{
	height: 25px;
	width: 25px;
	background-color: red;
	border-radius: 50%;
}

.text-vanish {
	animation: textvanish ease-in 500ms forwards;
}
 @keyframes textvanish {
	0% {
		opacity: 1;
		transform: translate(0,0);
	}
	
	100% {
		opacity: 0;
		transform: translate(-200px,0);
	}
}

.text-inbound {
	animation: textinbound ease-in 500ms forwards;
}
 @keyframes textinbound {
	0% {
		opacity: 0;
		transform: translate(200px,0);
	}
	
	100% {
		opacity: 1;
		transform: translate(0px,0);
	}
}

.text-inbound-2 {
	animation: textinbound2 ease-in 200ms forwards;
}
 @keyframes textinbound2 {
	0% {
		opacity: 0;
		transform: translate(200px,0);
	}
	
	100% {
		opacity: 1;
		transform: translate(0px,0);
	}
}


.edit-confirmationmessage-button {
	animation: showButtonOptions ease-in 500ms forwards;
}
 @keyframes showButtonOptions {
	0% {
		width: 0px;
	}
	
	100% {
		width: 300px;
	}
}


.object-fade-out {
	animation: fadeOut ease-in 500ms forwards;
}
 @keyframes fadeOut {
	0% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

.object-fade-in {
	animation: fadeIn ease-in 500ms forwards;
}
 @keyframes fadeIn {
	0% {
		opacity: 0;
	}
	
	100% {
		opacity: 1;
	}
}

.preview-calendar-device{
	flex-direction: column;
    text-align: center;
    width: 60%;
    display: flex;
    margin: 3rem auto;
}

.chip{
	background-color: #d3e3fd;
    border-radius: 32px;
    cursor: pointer;
    font-family: 'Google Sans',Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    letter-spacing: .25px;
    line-height: 16px;
    transition: background-color .125s cubic-bezier(0.4,0,0.2,1);
    white-space: nowrap;
}

.field-apperance {
	background: #3F51B5;
    padding: 5px;
    border-radius: 5px;
    color: white;
}

.chats-column {
    height: calc(100vh - 150px);
    
	/* border-radius: 5px; */
	
}

.chats-user-parameters {
	padding: 5px 10px;
	min-height: 4rem;
	margin: 2rem 0 1rem 0;
	border-top: 1px solid rgb(219, 219, 219);

}

.support-agent-userinfo{
	display: flex;
    justify-content: left;
    border-right-color: 1px solid white;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 38vh;
    width: 40%;
}

.keyboard-arrow-box {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.flex-column{
	display: flex;
	flex-direction: column;
}

.charts-key-values-box{
	width: 180px;
}

.bootstrap-custom-textarea{
	border: none;
	background-color: rgb(253, 240, 208);
	width: 100%;
	outline:none;
}

.bootstrap-custom-textarea-sendMessage{
	border: 2px solid #f0f1f1;
	width: 100%;
	outline:none;
}
.react-date-picker__wrapper{
	border:none;
}

.react-date-picker__inputGroup{
	min-width: 0px;
}

.snack-twentyfourhourwindow{
	/* background-color: #2196f3; */
	color: #fff;
	font-weight: 500;
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
	max-width: 90%;
	display: flex;
	opacity: 1;
    transform: none;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	padding: 6px 16px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;


}

.crm-events{
	display: flex;
    /* border-bottom: 1px solid rgb(219, 219, 219); */
    /* border-top: 1px solid rgb(219, 219, 219); */
    padding: 5px 5px;
    overflow: hidden scroll;
    border-radius: 10px;
    /* margin-left: 2rem; */
    /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
    /* margin: 0px 10px 20px; */
}

.crm-events-status-pending{
	margin:"0 10px 0 0";
	background: #FFF7E6;
	padding: 5px;
	border-radius: 10px;
}
.crm-events-status-text-pending{
	color: #FCB724;
}
.crm-events-status-active{
	margin:"0 10px 0 0";
	background: #EDFAF5;
	padding: 5px;
	border-radius: 10px;
}
.crm-events-status-text-active{
	color: #4caf9f;
}
.crm-events-status-canceled{
	margin:"0 10px 0 0";
	background: #faedf2;
	padding: 5px;
	border-radius: 10px;
}
.crm-events-status-text-canceled{
	color: #af4c5c;
}

.calendar-list{
	padding: 0 0 0 3rem;
	width: fit-content;
	max-height: 30vh;
    overflow: hidden scroll;
    border-left: 1px solid grey;

}

.settings-panel-tab{
	max-width: 90vw;
}

.blog-p{
	font-size: 22px;
	margin: 20px 0;

}
.blog-h{
	margin: 30px 0 20px 0;

}

.blog-element-center{
	width: 100%;
	display: flex;
	justify-content: center;
}

.blog-header {
	background-color: rgb(242, 242, 242);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	width: 100%;
	margin: 0 0 20px 0;
	z-index: 100;
	/* max-width: 90%; */

}




.docs-blog-preview{
	width:360px;
	height: 110px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin: 1rem 2rem 1rem 0;
	box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%);
}

.docs-blog-preview:hover{
	width:360px;
	height: 110px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin: 1rem 2rem 1rem 0;
	box-shadow: #3f51b5 0px 5px 15px;
}

@media (max-width: 601px) {
	.docs-blog-preview{
		height: unset;
	}
	.docs-blog-preview:hover {
		height: unset
	}

}



.event-templates-small{
	width:220px;
	height: 60px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin: 1rem 2rem 1rem 0;
	/* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
}

.event-templates-small:hover{
	width:220px;
	height: 60px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin: 1rem 2rem 1rem 0;
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.event-templates-dialog{
	width:220px;
	height: 60px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin:  1rem auto;
	/* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
}

.event-templates-dialog:hover{
	width:220px;
	height: 60px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin: 1rem auto;
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.help-howto-templates-small{
	height: 60px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin: 1rem 2rem 1rem 0;
	/* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
}

.help-howto-templates-small:hover{
	height: 60px;
	display: flex;
	border-radius: 1rem;
	background-color: #fff;
	margin: 1rem 2rem 1rem 0;
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

@media (min-width: 601px) {

	.ticket-table {
	
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 150px;
	font-weight: bold;
	text-align: center;
   
	  }

	.ticket-table-status {
  
	
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 80px;
	font-weight: bold;
	text-align: center;
   
	  }

	.ticket-table-view-ticket {
	
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 80px;
	font-weight: bold;
	text-align: center;
   
	}

}

@media (max-width: 601px) {

	.ticket-table {
	
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100px;
	font-weight: bold;
	text-align: center;
   
	  }

	.ticket-table-status {
  
	
	display: none;
	justify-content: center;
	flex-direction: column;
	width: 8px;
	font-weight: bold;
	text-align: center;
   
	  }

	.ticket-table-view-ticket {
	
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 80px;
	font-weight: bold;
	text-align: center;
   
	}

}

.popper-element {
	display:flex;
	padding:0.75rem 0.75rem;
}
.popper-element:hover {
	background:#e0dede;
	cursor: pointer;
}

.signin-google-btn{
	background: #fff;
	border: 1px solid #979797;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	height: 46px;
	line-height: 46px;
	text-align: center;
}

.MuiAlert-message{
	padding:4px 0;
	width: 100%;
}

.event-templates{
	width: 205px;
	height: 150px;
	margin: 0.5rem 1rem;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media (max-width: 601px){
	.cookie-consent-wrap-buttons{
		margin: -25px 0 10px auto;
	}
}
@media (min-width: 601px){
	.cookie-consent-wrap-buttons{
		margin: auto 0;
	}
}

.f{
	display: flex;
}
.sb{
	justify-content: space-between;
}

.affiliate-payout-activity-entry {
	align-items: center;
	padding: 8px;
	border-bottom: 1px solid #ccc;
	background-color: #fff;
    border: 0.0625rem solid rgba(12,12,13,.1);
    border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 1rem;
  }
  
  .affiliate-payout-date {
	padding: 5px 0 0px 10px;
  }
  
  .affiliate-payout-name {
	flex: 3;
	font-size: 14px;
	margin-left: 8px;
	font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
  
  .affiliate-payout-payoutMethod {
	padding: 5px 0 0px 0px;
  }
  
  .affiliate-payout-amount {
	flex: 1;
	font-size: 14px;
	margin-left: 8px;
	text-align: right;
	font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  textarea {
	flex: 1;
	padding: 8px;
	border: none;
	border-radius: 8px;
	margin-right: 8px;
  }

  .platform-image-lp {
	opacity: 0;
	transition: opacity 500ms;
	max-width: 100%;
	display: none;
  }
  
  .platform-image-lp.active {
	opacity: 1;
	display: flex;
  }

 
  .chats-message-container {
	max-width: 400px;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    white-space: pre-wrap;
    word-break: break-word;
    background-color: #0b93f6;
	line-height: 24px;
	margin-bottom: 12px;
	font-size: 14px;
  }
  
  .chats-message-button {
	display: block;
    margin: 5px auto;
    padding: 3px 15px;
    border: none;
    border-radius: 10px;
    background-color: #fff;
    color: #0b93f6;
	text-align: center;
  }
  
.map-container{
	width:100%;
	height:25vh;
}

.places-container{
	/* position: absolute; */
	top:10px;
	left:50%;
	transform: translateX("-50%");
	z-index: 10;
	width: 100%;

}

.combobox-input{
	width: 100%;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    padding: 5px 4px;
    margin: 5px 0;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.collapsing {
	visibility: hidden;
	height: 0;
	transition: visibility 0s ease-in-out 0.3s, height 0.3s ease-in-out;
  }
  
  .collapsed {
	visibility: hidden;
	height: 0;
  }
  
  .expanding {
	visibility: "";
	height: "";
	transition: visibility 0s ease-in-out, height 0.3s ease-in-out 0.3s;
  }
  
  .expanded {
	visibility: "";
	height: "";
  }
  
.suboption{
	opacity: 1;
	transition: opacity 300ms ease-out;
  }
  
  .suboption.removing {
	opacity: 0;
  }

.button-calendarApp-primary{
	background: rgb(25, 118, 210);
	color: white;
	padding: 10px;
	border-radius: 4px;
	font-weight: bold;
	border: 2px solid rgb(25, 118, 210);
	
}
.button-calendarApp-primary:hover{
	cursor: pointer;
	background: white;
	color: rgb(25, 118, 210);
	padding: 10px;
	border-radius: 4px;
	font-weight: bold;
	border: 2px solid;
}



.inner-container-box {
	min-height:400px;
	display:flex;
	justify-content:start;
	overflow:hidden;
	max-width: 500px;
	border: 1px solid #f2f2f2;
	background-color: white;
	box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
	border-radius: 10px;
    padding: 2rem;
    flex-direction: column;
    width: 500px;
    height: fit-content;
	margin: 2rem;
	
	}

.outer-container-box {
	display:flex;
	justify-content:center;
	padding: 2rem 0 0 0;
	overflow: scroll;
}

@media (max-width: 600px){
	.outer-container-box {
		padding: 0;

	  }
	  .inner-container-box {
		min-height:400px;
		display:flex;
		justify-content:start;
		overflow:hidden;
		max-width: 500px;
		background-color: white;
		border-radius: 10px;
		padding: 2rem;
		flex-direction: column;
		margin: 0;
		box-shadow:unset;
		border-radius:0;
	  }
}

@media (max-width: 601px){

}

.restaurant-setup-navigation-bar{
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	background: white;
	z-index: 1000;
	padding: 0 1rem 10px;
}

.select-calendarApp-template{
	background-color: rgb(242, 242, 242);
	border: 2px solid rgb(242, 242, 242);
	color: grey;
	margin-top: 10px;
	border-radius: 4px;
	width: 100%;
	padding: 12px 10px;
}
.select-calendarApp-template:hover{
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
