.live-chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chat-button {
    width: 70px; /* Increased width */
    height: 70px; /* Increased height */
    background-color: rgb(242, 242, 242);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.chat-button img {
    width: 34px; /* Adjusted size to fit the new button size */
    height: 34px; /* Adjusted size to fit the new button size */
}

.chat-window {
    width: 350px; 
    height: 450px; 
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 100px; /* Adjusted to accommodate the larger button */
    right: 20px;
	overflow: hidden;
	overscroll-behavior: contain;
}

.chat-header {
    padding: 10px;
    background-color: rgb(242, 242, 242);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.chat-header h3 {
    margin: 0;
    font-size: 1.5rem;
}

.subheader {
    margin: 0;
    font-size: 0.85rem;
    color: #555;
}

.chat-body {
	flex: 1;
	padding: 10px;
	overflow-y: auto;
	min-height: 0; /* Ensures proper flex behavior */
	overscroll-behavior: contain; /* Contain scroll within this element */
	-webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

.chat-message {
    margin-bottom: 10px;
}

.chat-message.user {
    text-align: right;
}

.chat-message span {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #f1f1f1;
    max-width: 70%;
}

.chat-message.user span {
    background-color: #dcf8c6;
}

.chat-message .user-message small {
    margin-left: 8px;
    color: #555;
    font-size: 0.75rem;
}

.support-message {
    display: flex;
    align-items: flex-end;
}

.support-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.chat-input {
    padding: 10px;
    display: flex;
    align-items: center;
}

.chat-input .MuiTextField-root {
    flex: 1;
}

.chat-input .MuiIconButton-root {
    margin-left: 10px;
}

.chat-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    background-color: #f9f9f9;
}

.chat-input {
    flex-grow: 1;
    padding-left: 8px;
}

.chat-input-container .MuiIconButton-root {
    padding: 8px;
}
